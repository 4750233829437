// services/api.js
import axios from 'axios';

const API_BASE_URL = 'https://ram-rajyam-backend.onrender.com/api/web';
export const fetchBlogs = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getallnews`);
    return response.data;
  } catch (error) {
    console.error('Error fetching blogs:', error);
    throw error;
  }
};
