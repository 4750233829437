// import React from 'react';
// import { Link } from 'react-router-dom';

// const HomeSIngleService = ({ icon, title, border_class}) => {
//    return (
//       <>
//          <div className="col-xl-4 col-lg-6 col-md-6">
//             <div className={border_class ? `service-box ${border_class} text-center mb-30` : 'service-box text-center mb-30'}>
//                <div className="service-thumb">
//                   <img src={`img/services/service${icon}.png`} alt=""/>
//                </div>
//                <div className="service-content">
//                   <h3><Link to="/servicesDetails">{title}</Link></h3>
//                   <p>छत्तीसगढ़ प्रदेश कुर्मी समाज रायपुर नगर के अध्यक्ष चुने गए भक्त भूषण चन्द्रवंशी ।</p>
//                   <Link className="service-link" to="/servicesDetails">और पढ़ें |</Link>
//                 <div>
//                     <span className="meta-date"><i className="far fa-calendar"></i>23rd Jan 2022</span>
//                 </div>
//                </div>
//             </div>
//          </div>
//       </>
//    );
// };

// export default HomeSIngleService;



import React from 'react';
import { Link } from 'react-router-dom';

const HomeSingleService = ({ icon, title, description, date, border_class }) => {
   return (
      <>
         <div className="col-xl-4 col-lg-6 col-md-6">
            <div className={border_class ? `service-box ${border_class} text-center mb-30` : 'service-box text-center mb-30'}>
               <div className="service-thumb">
                  <img src={`img/services/service${icon}.png`} alt=""/>
               </div>
               <div className="service-content">
                  <h3><Link to="/servicesDetails">{title}</Link></h3>
                  <p>{description}</p>
                  <Link className="service-link" to="/servicesDetails">और पढ़ें |</Link>
                  <div>
                     <span className="meta-date"><i className="far fa-calendar"></i>{date}</span>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default HomeSingleService;


