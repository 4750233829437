import React from 'react';
import './topbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

const TopBar = () => {
    return (
        <div className="top-bar">
            <div className="logo">
                <img src='./img/logo/logonew2.png' alt="Logo" />
            </div>
            
            <div className="contact-info">
                <div className="phone-icon">
                    <FontAwesomeIcon icon={faPhone} />...
            </div>
                <span>+91 9246622633</span>
                <div className="email-icon">
                <i class="fa fa-envelope" aria-hidden="true"></i>...
                </div>
                <span>rkdigitals@gmail.com</span>
            </div>
            <div className="social-media">
                <a href="#"><FontAwesomeIcon icon={faFacebook} /></a>
                <a href="#"><FontAwesomeIcon icon={faTwitter} /></a>
            </div>
        </div>
    );
};

export default TopBar;
