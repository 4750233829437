import React from 'react';
import './HomeFooter.css'; // Assuming you will create a separate CSS file for styling
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div>
            <footer className="footer">
                <div className="footer-section">
                    <h4 style={{color:"white"}}>हमारे बारे में</h4>
                    <p style={{color:"#999"}}>
                        छत्तीसगढ़ राज्य कुर्मी क्षत्रिय समाज की स्थापना 2006 में की गई थी। छत्तीसगढ़ के पहले मुख्यमंत्री, माननीय डॉ. खुबचंद बघेल जी, और कई महान हस्तियाँ कुर्मी समुदाय की उन्नति, आर्थिक, राजनीतिक, सामाजिक, शैक्षिक, और सांस्कृतिक विकास के समर्थक थे।
                    </p>
                </div>
                <div className="footer-section">
                    <h4 style={{color:"white"}}>मेन्यू</h4>
                    <ul style={{color:"#999"}}>
                        <li><a style={{color:"#999"}} href="#">हमारे बारे में</a></li>
                        <li><a style={{color:"#999"}} href="#">समाचार और घटनाएँ</a></li>
                        <li><a style={{color:"#999"}} href="#">विशेषताएँ</a></li>
                        <li><a style={{color:"#999"}} href="#">गैलरी</a></li>
                        <li><a style={{color:"#999"}} href="#">हमसे संपर्क करें</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4 style={{color:"white"}}>संपर्क करें</h4>
                    <address style={{color:"#999"}}>
                        महादेव घाट रोड, सुंदरनगर, रायपुर (छ.ग.) - 492 001<br />
                        <Link style={{color:"#999"}} to="tel:+919329163838">+91 9246622633</Link><br />
                        <Link style={{color:"#999"}} to="mailto:info@chhattisgarhkurmisamaj.com">rkdigitals@gmail.com</Link>
                    </address>
                    <div className="social-links" style={{color:"blue"}}>
                        <a style={{color:'#e74c3c'}} href="#"><i className="fab fa-facebook-f"></i></a>
                        <a style={{color:'#e74c3c'}} href="#"><i className="fab fa-twitter"></i></a>
                        <a style={{color:'#e74c3c'}} href="#"><i className="fab fa-linkedin-in"></i></a>
                        <a style={{color:'#e74c3c'}} href="#"><i className="fab fa-instagram"></i></a>
                    </div>
                </div>
            </footer>
            <div className="footer-bottom pt-25 pb-20">
                <div className="containermainfooter">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="footer-copyright text-center">
                                <p className="white-color">कॉपीराइट्स © 2010 - 2024<span style={{color:'#e74c3c'}}> मारवाड़ी समाज</span>. सर्वाधिकार सुरक्षित। पावर्ड बाय : <span style={{color:'#e74c3c'}}>सॉफ्टबिट सॉल्यूशन</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    );
}

export default Footer;
