import React from 'react';

const CommonSectionArea = ({area_header}) => {
   return (
      <>
         <section className="about-area pt-120 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-5">
                     {area_header}
                  </div>
                  <div className="col-xl-6 col-lg-7">
                     <div className="about-right-side pt-55 mb-30">
                        <div className="about-title mb-20">
                           <h5>हमारे बारे में</h5>
                           <h1>
                           मारवाड़ी समाज के बारे में लघु कहानी।</h1>
                        </div>
                        <div className="about-text mb-50">
                           <p>छत्तीसगढ़ प्रदेश कुर्मी क्षत्रिय समाज का गठन वर्ष 2006 में किया गया। छ.ग. राज्य के प्रथम स्वप्नदृष्टा परम श्रद्धेय डाॅ. खूबचंद बघेल जी समस्त फिरकों को एक सूत्र में बाॅधकर कुर्मी समाज के उत्तरोत्तर आर्थिक, राजनैतिक, सामाजिक, शैक्षणिक, सांस्कृतिक विकास के पक्षधर थे। उनके इस दूरदर्शी स्वप्न को साकार करके प्रदेश संगठन द्वारा 22 फरवरी 2008 को सप्रे शाला मैदान में एक विराट रैली एवं सम्मेलन का आयोजन किया गया। इस आयोजन में प्रदेश भर के कोने-कोने से एकत्रित कुर्मी स्वजातिय बंधुओं ने सामाजिक एकजुटता का स्पष्ट संदेश दिया था। प्रदेश संगठन द्वारा लाखों की संख्या में उपस्थित स्वजातिय बंधुओं के बीच नाम के पहले ’कुर्मी’ शब्द का उपयोग करने संबंधी प्रस्ताव को सर्व सम्मति से पारित किया गया।</p>
                        </div>
                        <div className="our-destination">
                           <div className="single-item mb-30">
                              <div className="mv-icon f-left">
                                 <img src="img/about/destination-icon-1.png" alt=""/>
                              </div>
                              <div className="mv-title fix">
                                 <h3>Our Mission</h3>
                                 <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse.</p>
                              </div>
                           </div>
                           <div className="single-item">
                              <div className="mv-icon f-left">
                                 <img src="img/about/destination-icon-2.png" alt=""/>
                              </div>
                              <div className="mv-title fix">
                                 <h3>Our Vission</h3>
                                 <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in voluptate velit esse.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonSectionArea;