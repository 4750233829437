import React from 'react';
import './Icons.css';

const Icons = () => {
  const items = [
    { icon: 'Achievers.png', label: 'उपलब्धियाँ' },
    { icon: 'Blood Zone.png', label: 'रक्त क्षेत्र' },
    { icon: 'Vrat aur tyohar.png', label: 'व्रत और त्योहार' },
    { icon: 'Business Network.png', label: 'व्यवसाय नेटवर्क' },
    { icon: 'Classified.png', label: 'वर्गीकृत' },
    // { icon: 'Committee.png', label: 'समिति' },
    { icon: 'Donation.png', label: 'दान' },
    // { icon: 'Download.png', label: 'डाउनलोड' },
    { icon: 'E-Commerce.png', label: 'ई-कॉमर्स' },
    { icon: 'Events.png', label: 'घटनाएँ' },
    { icon: 'Gallery.png', label: 'गैलरी' },
    { icon: 'Jobs.png', label: 'नौकरियाँ' },
    { icon: 'Kuldevi and kuldevta.png', label: 'कुलदेवी और कुलदेवता' },
    // { icon: 'Magazine.png', label: 'पत्रिका' },
    { icon: 'Matrimony.png', label: 'विवाह' },
    { icon: 'Members.png', label: 'सदस्य' },
    { icon: 'News.png', label: 'समाचार' },
    { icon: 'Organisations.png', label: 'संगठन' },
    { icon: 'Panchang.png', label: 'पंचांग' },
    { icon: 'Pandit ji.png', label: 'पंडित जी' },
    { icon: 'Rasam.png', label: 'रसम' },
    { icon: 'Sponsor.png', label: 'प्रायोजक' },
    // { icon: 'Suggestion.png', label: 'सुझाव' },
    { icon: 'Volunteers.png', label: 'स्वयंसेवक' }
  ];

  return (
    <div className='mt-30 '>
    
    <h1 className="heading">
  <span class="half-black">विशे</span> <span class="half-red">षताएँ</span> 
  <br/>
  <img src="img/shape/section-title-line.png" alt="" />
</h1>
      <div className="app">
        <div className="grid-container ">
          {items.map((item, index) => (
            <div className="grid-item" key={index}>
              <div className="icon-container">
                <img src={`/img/icons/${item.icon}`} alt={item.label} className="icon" />
              </div>
              <div className="label">{item.label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Icons;
