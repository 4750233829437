import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './NewsEvents.css';

const S3_BASE_URL = 'https://ramrajyam-images-uploads.s3.ap-south-1.amazonaws.com/';

const NewsEvents = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemType, setItemType] = useState(null); // 'news' or 'event'

  useEffect(() => {
    // Fetch news items
    axios.get('https://ram-rajyam-backend.onrender.com/api/web/getallnews')
      .then(response => {
        setNewsItems(response.data.items || []);
        setLoading(false); // Set loading to false after fetching data
      })
      .catch(error => {
        console.error('Error fetching news items:', error);
        setLoading(false); // Set loading to false if there is an error
      });

    // Fetch events
    axios.get('https://ram-rajyam-backend.onrender.com/api/web/events')
      .then(response => {
        setEvents(response.data.items || []);
        setLoading(false); // Set loading to false after fetching data
      })
      .catch(error => {
        console.error('Error fetching events:', error);
        setLoading(false); // Set loading to false if there is an error
      });
  }, []);

  // Function to format date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('hi-IN', options); // Format date in Hindi
  };

  // Function to handle news item click
  const handleNewsClick = (newsItem) => {
    setSelectedItem(newsItem);
    setItemType('news');
  };

  // Function to handle event click
  const handleEventClick = (event) => {
    setSelectedItem(event);
    setItemType('event');
  };

  // Function to close the pop-up
  const closePopup = () => {
    setSelectedItem(null);
    setItemType(null);
  };

  return (
    <div className='containermain mt-20'>
     <h1 className="heading">
  <span class="half-black">समाज </span> <span class="half-red">न्यूज़</span> 
  <br/>
  <img src="img/shape/section-title-line.png" alt="" />
</h1>
      <div className="container">
        <div className="content">
          {loading ? (
            <div className="loading">लोड हो रहा है...</div> // Loading indicator
          ) : (
            <>
              <div className="news-section">
                {newsItems.map((item, index) => (
                  <div 
                    className="news-item" 
                    key={index} 
                    onClick={() => handleNewsClick(item)}
                  >
                    <img 
                      src={`${S3_BASE_URL}${item.image}`} 
                      alt={item.title} 
                      className="news-image" 
                    />
                    <div className="news-details">
                      <h2 className="news-title">{item.title}</h2>
                      <p className="news-date">{formatDate(item.createdAt)}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="events-section">
                <h2 className="events-heading">आयोजन</h2>
                <ul className="events-list">
                  {events.map((event, index) => (
                    <li 
                      key={index} 
                      className="event-item" 
                      onClick={() => handleEventClick(event)}
                    >
                      {event.title}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>

      {selectedItem && (
        <div className="popup mt-80">
          <div className="popup-content">
            <button className="popup-close" onClick={closePopup}>Close</button>
            {itemType === 'news' ? (
              <>
                <h2 className="popup-title">{selectedItem.title}</h2>
                <img 
                  src={`${S3_BASE_URL}${selectedItem.image}`} 
                  alt={selectedItem.title} 
                  className="popup-image" 
                />
                <p className="popup-date">{formatDate(selectedItem.createdAt)}</p>
                <p className="popup-description">{selectedItem.description}</p>
              </>
            ) : (
              <>
                <h2 className="popup-title">{selectedItem.title}</h2>
                <img 
                  src={`${S3_BASE_URL}${selectedItem.image}`} 
                  alt={selectedItem.title} 
                  className="popup-image" 
                />
                <p className="popup-description">{selectedItem.description}</p>
                <p className="popup-date">{formatDate(selectedItem.createdAt)}</p>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsEvents;
