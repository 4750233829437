import React from 'react';
import './ContactUs.css';
import TopBar from '../TopBar/topBar';
import NavBar from '../NavBar/navBar';
import Footer from '../Home/Home/HomeFooter/HomeFooter';

const ContactUs = () => {
  return (
  <div>
      <TopBar/>
      <NavBar/>
    <div className='mt-60 '>  <h1 className="heading">
  <span class="half-black">संपर्क </span> <span class="half-red">करें</span> 
  <br/>
  <img src="img/shape/section-title-line.png" alt="" />
</h1></div>
    <div className="contact-us">
      
      {/* Contact Information */}
      <div className="contact-info1">
      
        <div className="contact-details">
          <h3>मारवाड़ी समाज</h3>
          <p>महादेव घाट रोड, सुंदरनगर, रायपुर (छ.ग.) - 492 001</p>
          <p>📞 +91 9246622633</p>
          <p>📧 rkdigitals@gmail.com</p>
          <p>(if any query then mail this ID)</p>
          <p>🌐 <a href="http://www.chhattisgarhkurnimajaj.com">www.chhattisgarhkurnimajaj.com</a></p>
          <div className="social-links">
            <a href="#"><i className="fa fa-facebook"></i></a>
            <a href="#"><i className="fa fa-twitter"></i></a>
            <a href="#"><i className="fa fa-linkedin"></i></a>
            <a href="#"><i className="fa fa-instagram"></i></a>
          </div>
        </div>
      </div>

      {/* Location Map */}
      <div className="location-map">
        {/* <h2 className="location-title">लोकेशन मैप</h2> */}
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.839912128964!2d81.64066301504265!3d21.251124585854485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28ddd2eaa50645%3A0x3d938b4d40bba3a0!2sChandrakaar%20Hostel%20mangal%20bhavan%2C%2054%2F976A%2C%20Daga%2C%20Amanaka%2C%20Raipur%2C%20Chhattisgarh%20492010!5e0!3m2!1sen!2sin!4v1625584143192!5m2!1sen!2sin"
            width="600"
            height="450"
            className="map"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default ContactUs;
