import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import './Gallery.css'; // Import the CSS file for styling
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const S3_BASE_URL = 'https://ramrajyam-images-uploads.s3.ap-south-1.amazonaws.com/';

const GalleryImages = () => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get('https://ram-rajyam-backend.onrender.com/api/web/gallery');
                console.log('API response:', response.data.galleries);

                const fetchedImages = response.data.galleries.flatMap(gallery =>
                    gallery.images.map(image => {
                        const imageUrl = `${S3_BASE_URL}${image}`;
                        console.log('Image URL:', imageUrl); // Debug the URL
                        return {
                            src: imageUrl,
                            alt: gallery.name,
                            name: image // Use the filename as the name
                        };
                    })
                );

                console.log('Fetched images:', fetchedImages);
                setImages(fetchedImages);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchImages();
    }, []);

    if (loading) return <p>चित्र लोड हो रहे हैं...</p>;
    if (error) return <p>त्रुटि: {error}</p>;

    // Slider settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Show 4 images at once
        slidesToScroll: 1, // Scroll one image at a time
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <section className="gallery-section">
               <h1 className="heading">
  <span class="half-black">फोटो</span> <span class="half-red">गैलरी</span> 
  <br/>
  <img src="img/shape/section-title-line.png" alt="" />
</h1>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Slider {...settings}>
                            {images.length > 0 ? (
                                images.map((image, index) => (
                                    <div key={index} className="slide-item">
                                        <div className="img-box">
                                            <img src={image.src} alt={image.alt} />
                                        </div>
                                        <div className="text-overlay">
                                            <h3>{image.name}</h3> {/* Display the image name */}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>कोई चित्र उपलब्ध नहीं है</p>
                            )}
                        </Slider>
                    </div>
                    <div className="info-cards">
                        <div className="card">
                            <img src="/img/gallery/enquiry.png" alt="Enquiry" />
                            <span style={{fontWeight:"bold", paddingTop:"10px"}}>पूछताछ</span>
                        </div>
                        <div className="card">
                            <img src="/img/gallery/feedback.png" alt="Feedback" />
                            <span style={{fontWeight:"bold", paddingTop:"10px"}}>प्रतिक्रिया</span>
                        </div>
                        <div className="card">
                            <img src="/img/gallery/suggestion.png" alt="Suggestions" />
                            <span style={{fontWeight:"bold", paddingTop:"10px"}}>सुझाव</span>
                        </div>
                        <div className="card">
                            <img src="/img/gallery/contact.png" alt="Contact Us" />
                            <span style={{fontWeight:"bold", paddingTop:"10px"}}>संपर्क करें</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GalleryImages;
