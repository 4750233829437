import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../../../components/Shared/Sidebar/Sidebar';
import useGlobalContext from '../../../../hooks/useGlobalContext';
import Icons from '../../Icons/icons';

const HomeHeader = () => {
   const [show, setShow] = useState(false);
   const [scrollTo, setScrollTo] = useState('');
   const { stickyMenu } = useGlobalContext();

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const handleScroll = (sectionId) => {
      setScrollTo(sectionId);
      const element = document.getElementById(sectionId);
      if (element) {
         element.scrollIntoView({ behavior: 'smooth' });
      }
   };

   const handleDownload = () => {
      const pdfUrl = '/pdf/marwadiPdf.pdf'; // Replace with the path to your PDF file
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = 'marwadiPdf.pdf'; // Replace with the desired file name
      link.click();
   };

   useEffect(() => {
      // Optionally handle something when scrollTo changes, if needed
   }, [scrollTo]);

   return (
      <>
         <header>
            <div className={stickyMenu ? "header-menu-area sticky_menu" : "header-menu-area"}>
               <div className="container menu_wrapper">
                  <div className="row align-items-center">
                     <div className="col-xl-3 col-lg-3 col-md-6 col-6 d-flex align-items-center">
                        <div className='d-flex'>
                           <Link to="/"><img src="img/logo/logo.png" height={60} alt="" /> </Link>
                           <h3 style={{ marginTop: "20px" }}>मारवाड़ी समाज</h3>
                        </div>
                     </div>
                     <div className="col-xl-9 col-lg-9 col-md-6 col-6">
                        <div className="header__menu f-right">
                           <nav id="mobile-menu">
                              <ul>
                                 <li><Link to="/" onClick={() => handleScroll('home')}>मुख्य पृष्ठ</Link></li>
                                 <li><Link to="#" onClick={() => handleScroll('about-us')}>हमारे बारे में</Link></li>
                                 <li><Link to="#" onClick={() => handleScroll('events')}>समाचार एवं आयोजन</Link></li>
                                 <li><Link to="#" onClick={() => handleScroll('features')}>विशेषताएँ</Link></li>
                                 <li><Link to="#" onClick={() => handleScroll('gallery')}>गेलरी</Link></li>
                                 <li><a href="/contact">संपर्क करें</a></li>
                                 <li><a style={{cursor:"pointer"}} onClick={handleDownload}>डाउनलोड</a></li>
                              </ul>
                           </nav>
                        </div>

                        <div className="side-menu-icon d-lg-none text-end">
                           <button onClick={handleShow} className="side-toggle border-0 bg-transparent">
                              <i className="fas fa-bars"></i> 
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>

         <Sidebar show={show} handleClose={handleClose} />
         
         <div className={`icons-container ${scrollTo === 'features' ? 'visible' : 'hidden'}`}>
            <Icons />
         </div>
      </>
   );
};

export default HomeHeader;
