import React, { useEffect, useState } from 'react';
import './navBar.css';
import { Link } from 'react-router-dom';

const NavBar = () => {
    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleClick = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <nav className={`navbarmy ${isFixed ? 'fixed' : ''}`}>
            <ul className="nav-linksmy">
                <li><Link to='/' onClick={() => handleClick('home')}><i className="fas fa-home"></i> होम</Link></li>
                <li><a href="#" onClick={() => handleClick('about-us')}> हमारे बारे में</a></li>
                <li><a href="#" onClick={() => handleClick('features')}> विशेषताएँ</a></li>
                <li><a href="#" onClick={() => handleClick('events')}> समाचार एवं घटनाएँ</a></li>
                <li><a href="#" onClick={() => handleClick('gallery')}> गैलरी</a></li>
                <li><Link to='/contact'> संपर्क करें</Link></li>
            </ul>
        </nav>
    );
};

export default NavBar;
