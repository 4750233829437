import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import axios from 'axios';
import HomeHeroSingleSlide from '../../../../components/HomeHeroSingleSlide/HomeHeroSingleSlide';
import VideoPopup from '../../../../components/VideoPopup/VideoPopup';
import useGlobalContext from '../../../../hooks/useGlobalContext';

const HomeHeroSection = () => {

   const { SlickArrowLeft, SlickArrowRight } = useGlobalContext();
   const [slides, setSlides] = useState([]);

   useEffect(() => {
       // Fetch banner images from the API
       const fetchBanners = async () => {
           try {
               const response = await axios.get('https://ram-rajyam-backend.onrender.com/api/web/userbanners');
               const bannerData = response.data.items; // Accessing items from the response

               if (!bannerData || !Array.isArray(bannerData)) {
                   console.error('Invalid banner data:', bannerData);
                   return;
               }

               // Construct full image URLs assuming the images are hosted at a base URL
               const imageUrls = bannerData.map(banner => `https://ramrajyam-images-uploads.s3.ap-south-1.amazonaws.com/${banner.image}`);
               setSlides(imageUrls);
           } catch (error) {
               console.error('Error fetching banner images:', error);
           }
       };

       fetchBanners();
   }, []);

   const settings = {
      autoplay: false,
      autoplaySpeed: 10000,
      dots: false,
      fade: true,
      arrows: true,
      prevArrow: <SlickArrowLeft />,
      nextArrow: <SlickArrowRight />,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               infinite: true,
            }
         },
         {
            breakpoint: 991,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false
            }
         },
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               arrows: false
            }
         }
      ]
   };

   return (
      <>
         <VideoPopup videoId="oU_GUAWz52w" />
         <section className="hero-area">
            <div className="hero-slider">
               <Slider {...settings}>
                  {slides.map((slide, index) => (
                      <div key={index} className="banner-slide">
                          <img src={slide} alt={`Slide ${index + 1}`} className="banner-image" />
                      </div>
                  ))}
               </Slider>
            </div>
         </section>
      </>
   );
};

export default HomeHeroSection;
