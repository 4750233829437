import React from 'react';
import GalleryImages from './GalleryImages';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Gallery() {
    return (
        <div >
            
            <GalleryImages />
        </div>
    );
}

export default Gallery;
