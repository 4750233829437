import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useGlobalContext from '../../../hooks/useGlobalContext';
import Sidebar from '../Sidebar/Sidebar';

const HomeFourFiveNavBar = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();
   return (
      <>
         <header>
            <div className="top-bar4 white-bg top-border d-none d-md-block pl-55 pr-55 pt-25 pb-25">
               <div className="container-fluid">
                  <div className="row d-flex align-items-center">
                     <div className="col-xl-6 col-lg-6">
                        <div className="header-info header-info4 p-0">
                           <span><i className="far fa-envelope-open"></i>ईमेल: infomail@medidove.com</span>
                           <span><i className="fal fa-phone"></i>+87 (676) 6765 764</span>
                           <span><i className="fal fa-clock"></i>रविवार - मंगलवार, 09:00 पूर्वाह्न - 08:00 अपराह्न</span>
                        </div>
                     </div>
                     <div className="col-xl-6 col-lg-6">
                        <div className="top4-right d-flex justify-content-end">
                           <div className="top4-menu">
                              <ul className="list-inline">
                                 <li><a className="need-help" href="#">मदद चाहिए?</a></li>
                                 <li><a href="#">सेटिंग और गोपनीयता</a></li>
                                 <li><a href="#">मीडिया</a></li>
                              </ul>
                           </div>
                           <div className="header-social-icons top4-social d-none d-xl-block">
                              <ul>
                                 <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                 <li><a href="#"><i className="fab fa-behance"></i></a></li>
                                 <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                 <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
                                 <li><a href="#"><i className="fab fa-pinterest"></i></a></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* <!-- menu-area --> */}
            <div className={stickyMenu ? "sticky_menu header-menu-area menu-area4 pl-55 pr-55" :
         "header-menu-area menu-area4 pl-55 pr-55"}>
               <div className="container-fluid">
                  <div className="row">
                     <div className="col-xl-8 col-lg-12 col-md-12 d-flex align-items-center navbar__wrapper">
                        <div className="logo pos-rel">
                           <Link to="/"><img src="img/logo/logo-4.png" alt="MediDove" /></Link>
                        </div>
                        <div className="header__menu header__menu4 pl-60">
                           <nav id="mobile-menu">
                              <ul>
                                 <li><Link to="/">होम +</Link>
                                    <ul className="submenu">
                                       <li><Link to="/home">होम स्टाइल 1</Link></li>
                                       <li><Link to="/homeTwo">होम स्टाइल 2</Link></li>
                                       <li><Link to="/homeThree">होम स्टाइल 3</Link></li>
                                       <li><Link to="/homeFour">होम स्टाइल 4</Link></li>
                                       <li><Link to="/homeFive">होम स्टाइल 5</Link></li>
                                    </ul>
                                 </li>
                                 <li><Link to="/services">विभाग +</Link>
                                    <ul className="submenu">
                                       <li><Link to="/services">सेवाएँ 01</Link></li>
                                       <li><Link to="/servicesTwo">सेवाएँ 02</Link></li>
                                       <li><Link to="/servicesDetails">सेवाएँ विवरण</Link></li>
                                    </ul>
                                 </li>
                                 <li><Link to="/doctors">डॉक्टर +</Link>
                                    <ul className="submenu">
                                       <li><Link to="/doctors">डॉक्टर 01</Link></li>
                                       <li><Link to="/doctorsTwo">डॉक्टर 02</Link></li>
                                       <li><Link to="/doctorDetails">डॉक्टर विवरण</Link></li>
                                    </ul>
                                 </li>
                                 <li><Link to="/shop">दुकान</Link>
                                    <ul className="submenu">
                                       <li><Link to="/shop">दुकान पृष्ठ</Link></li>
                                       <li><Link to="/shopDetails">दुकान विवरण</Link></li>
                                       <li><Link to="/shoppingCart">शॉपिंग कार्ट</Link></li>
                                       <li><Link to="/checkout">चेकआउट</Link></li>
                                       <li><Link to="/wishList">विशलिस्ट</Link></li>
                                       <li><Link to="/login">लॉगिन</Link></li>
                                       <li><Link to="/register">रजिस्टर</Link></li>
                                    </ul>
                                 </li>
                                 <li><Link to="/blogs">समाचार +</Link>
                                    <ul className="submenu">
                                       <li><Link to="/blogs">ब्लॉग दाएं साइडबार</Link></li>
                                       <li><Link to="/blogLeftSideBar">ब्लॉग बाएं साइडबार</Link></li>
                                       <li><Link to="/blogNoSideBar">ब्लॉग नो साइडबार</Link></li>
                                       <li><Link to="/blogTwoColumn">ब्लॉग 2 कॉलम</Link></li>
                                       <li><Link to="/blogTwoMasonry">ब्लॉग 2 कॉलम मैसनरी</Link></li>
                                       <li><Link to="/blogThreeColumn">ब्लॉग 3 कॉलम</Link></li>
                                       <li><Link to="/blogThreeColMasonry">ब्लॉग 3 कॉलम मैसनरी</Link></li>
                                       <li><Link to="/blogDetails">ब्लॉग विवरण</Link></li>
                                       <li><Link to="/blogDetailsLeftSidebar">विवरण बाएं साइडबार</Link></li>
                                       <li><Link to="/blogDetailsAudio">विवरण ऑडियो</Link></li>
                                       <li><Link to="/blogDetailsVideo">विवरण वीडियो</Link></li>
                                       <li><Link to="/blogDetailsGallery">विवरण गैलरी</Link></li>
                                    </ul>
                                 </li>
                                 <li><a href="#">पृष्ठ +</a>
                                    <ul className="submenu">
                                       <li><Link to="/about">हमारे बारे में</Link></li>
                                       <li><Link to="/appoinment">अपॉइंटमेंट</Link></li>
                                       <li><Link to="/portfolioTwoColumn">पोर्टफोलियो 2 कॉलम</Link></li>
                                       <li><Link to="/portfolioThreeColumn">पोर्टफोलियो 3 कॉलम</Link></li>
                                       <li><Link to="/portfolioSlider">पोर्टफोलियो स्लाइडर</Link></li>
                                       <li><Link to="/contact">संपर्क</Link></li>
                                       <li><Link to="/notMatch">404 पृष्ठ</Link></li>
                                    </ul>
                                 </li>
                                 <li><a href="#"><i className="fal fa-search"></i></a></li>
                              </ul>
                           </nav>
                        </div>

                        <div onClick={handleShow} className="side-menu-icon d-lg-none text-end">
                           <button className="side-toggle border-0 bg-transparent">
                              <i className="fas fa-bars"></i> </button>
                        </div>

                     </div>
                     <div className="col-xl-4 col-lg-9 col-md-9 d-none d-xl-block">
                        <div className="header-right d-flex align-items-center justify-content-end">
                           <div className="header-lang pos-rel d-none d-lg-block">
                              <div className="lang-icon">
                                 <img src="img/icon/lang.png" alt="" />
                                 <span>हिंदी<i className="fas fa-angle-down"></i></span>
                              </div>
                              <ul className="header-lang-list">
                                 <li><a href="#">USA</a></li>
                                 <li><a href="#">UK</a></li>
                                 <li><a href="#">CA</a></li>
                                 <li><a href="#">AU</a></li>
                              </ul>
                           </div>
                           <div className="header-button pl-50">
                              <Link to="/contact"
                                 className="primary_btn btn-icon ml-0" style={{ animationDelay: '0.6s' }} tabIndex="0">
                                 <span>+</span>अपॉइंटमेंट बुक करें</Link>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>

         <Sidebar show={show} handleClose={handleClose} />
      </>
   );
};

export default HomeFourFiveNavBar;
