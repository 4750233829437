import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HomeSingleTeam from '../../../../components/HomeSingleTeam/HomeSingleTeam';

const S3_BASE_URL = 'https://ramrajyam-images-uploads.s3.ap-south-1.amazonaws.com/';

const HomeOurTeam = () => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGalleryItems = async () => {
      try {
        const response = await axios.get('https://ram-rajyam-backend.onrender.com/api/web/gallery'); // Replace with your API endpoint
        setGalleryItems(response.data.galleries);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGalleryItems();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <section className="team-area mt-50 ">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-7 col-md-10">
            <div className="section-title pos-rel mb-10">
              <div className="section-text pos-rel">
                <h5>Gallery</h5>
                <h3 style={{color:'orange'}}>All Gallery </h3>
              </div>
              {/* <div className="section-line pos-rel">
                <img src="img/shape/section-title-line.png" alt="" />
              </div> */}
            </div>
          </div>
        </div>
        <div className="row">
          {galleryItems.map((item) => (
            <HomeSingleTeam
              key={item.id}
              image={`${S3_BASE_URL}${item.images[0]}`} // Use S3 URL
              title={item.name} // Replace with the correct property
              subtitle={item.category.name} // Replace with the correct property
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default HomeOurTeam;
