import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import HomeSingleBlogLeftSide from '../../../../components/HomeSingleBlogLeftSide/HomeSingleBlogLeftSide';
import HomeSingleBlogRightSide from '../../../../components/HomeSingleBlogRightSide/HomeSingleBlogRightSide';
import { fetchBlogs } from '../../../../services/api';

const S3_BASE_URL = 'https://ramrajyam-images-uploads.s3.ap-south-1.amazonaws.com/';

const HomeBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [events, setEvents] = useState([]);
  const eventsContainerRef = useRef(null);

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const data = await fetchBlogs();
        setBlogs(data?.items || []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    axios.get('https://ram-rajyam-backend.onrender.com/api/web/events')
      .then(response => {
        setEvents(response?.data?.items || []);
      })
      .catch(error => {
        console.error('Error fetching events data:', error);
      });

    getBlogs();
  }, []);

  useEffect(() => {
    const scrollToBottom = () => {
      if (eventsContainerRef.current) {
        requestAnimationFrame(() => {
          eventsContainerRef.current.scrollTop = eventsContainerRef.current.scrollHeight;
        });
      }
    };

    scrollToBottom();
  }, [events]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching blogs</p>;

  return (
    <section className="latest-news-area" >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-7">
            <div className="section-title pos-rel ">
              <div className="section-text pos-rel">
                <h5>News & Events</h5>
                <div className='d-flex'>
                  <div><h3 style={{ color: 'orange' }}>News</h3></div>
                  <div/>
                  <div><h3 style={{ color: 'orange',marginLeft:"680px"}}>Events</h3>
                 
                  </div>
                  
                </div>
                {/* <div className="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="row">


          <div className="col-xl-8 col-lg-8">

            <div className="row">

              {blogs.map(blog => (
                <div key={blog.id} className="col-md-4 mb-4">

                  <HomeSingleBlogLeftSide
                    image={`${S3_BASE_URL}${blog.image}`}
                    title={blog.title}
                    date={blog.createdAt}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="col-xl-4 col-lg-4">

            <div
              className="recent-news-list"
              style={{ height: '300px', overflowY: 'scroll' }}
              ref={eventsContainerRef}
            >
              {events.map((event, index) => (
                <HomeSingleBlogRightSide
                  key={index}
                  image={`${S3_BASE_URL}${event.image}`}
                  title={event.title}
                  date={event.createdAt}
                  border={index % 2 === 0 ? 'news-border-bottom' : ''}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBlogs;
