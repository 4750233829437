import React from 'react';
import { Link } from 'react-router-dom';

const HomeSingleBlogRightSide = ({ title, image, date, border }) => {
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <div className={border ? `latest-news-content singl-news ${border}` : `latest-news-content singl-news news-border-bottom`}>
      <h3 className="news-title">
        <Link to="/blogDetails">{title}</Link>
      </h3>
      <span className="meta-date">
        <i className="far fa-calendar"></i>{formattedDate}
      </span>
    </div>
  );
};

export default HomeSingleBlogRightSide;
