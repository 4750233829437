import React from 'react';
import HomeAboutArea from './HomeAboutArea/HomeAboutArea';
import HomeBlogs from './HomeBlogs/HomeBlogs';
import HomeCta from './HomeCta/HomeCta';
import HomeFact from './HomeFact/HomeFact';
import HomeFooter from './HomeFooter/HomeFooter';
import HomeHeader from './HomeHeader/HomeHeader';
import HomeHeroSection from './HomeHeroSection/HomeHeroSection';
import HomeOurTeam from './HomeOurTeam/HomeOurTeam';
import HomePricing from './HomePricing/HomePricing';
import HomeServices from './HomeServices/HomeServices';
import Icons from '../Icons/icons';
import CallToAction from '../CallToAction/callToAction';
import NewsEvents from '../NewsEvents/NewsEvents';
import Gallery from '../Gallery/Gallery';
import TopBar from '../../TopBar/topBar';
import { Navbar } from 'react-bootstrap';
import BannerSlider from '../../Banner/banner';
import NavBar from '../../NavBar/navBar';

const Home = () => {
    return (
        <>
         <TopBar/>
            <NavBar/>
            <section id="home"><HomeHeroSection/></section>
            {/* <section id="home"><BannerSlider/></section> */}
            <section id="about-us"><HomeAboutArea/></section>
            <section id="events"><NewsEvents/></section>
            <CallToAction/>
            <section id="features"><Icons /></section>
            <section id="gallery"><Gallery/></section>
            <HomeFooter/>
        </>
    );
};

export default Home;

// const Home = () => {
//     return (
//         <>
//             <HomeHeader/>
//             <section id="home"><HomeHeroSection/></section>
//             <section id="about-us"><HomeAboutArea/></section>
//             <section id="events"><NewsEvents/></section>
//             <CallToAction/>
//             <section id="features"><Icons /></section>
//             <section id="gallery"><Gallery/></section>
//             <HomeFooter/>
//         </>
//     );
// };


