import React from 'react';
import PropTypes from 'prop-types';
// import './HomeSingleBlogLeftSide.css'; // Assuming you have a CSS file for styling

const HomeSingleBlogLeftSide = ({ image, title, description, date }) => {
  return (
    <div className="single-blog-item">
      <div className="blog-img">
        <img src={image} alt={title} />
      </div>
      <div className="blog-content">
        <h4>{title}</h4>
        <p className="blog-date">{new Date(date).toLocaleDateString()}</p>
        <p>{description}</p>
      </div>
    </div>
  );
};

HomeSingleBlogLeftSide.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default HomeSingleBlogLeftSide;
