import React from 'react';
import './callToAction.css'; // Make sure to create and style this CSS file

const CallToAction = () => {
  return (
    <div className="cta-container">
      <div className="cta-content">
        <p className="cta-header" style={{ color: 'white' }}>
          मारवाड़ी समाज ऐप डाउनलोड करें
        </p>
        <h2 className="cta-title">मारवाड़ी समाज</h2>
        <div className="icons">
          <img src={`/img/icon/bothicon1.png`} alt="Icon 2" />
          {/* <img src={`/img/icon/icon3.png`} alt="Icon 1" /> */}
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
