import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Collapsible from 'react-collapsible';

const Sidebar = ({ show, handleClose }) => {

   const handleScroll = (sectionId) => {
      const element = document.getElementById(sectionId);
      if (element) {
         element.scrollIntoView({ behavior: 'smooth' });
         handleClose(); // Close sidebar after navigation
      }
   };

   return (
      <div>
         <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
            <Offcanvas.Header closeButton>
               {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
            </Offcanvas.Header>
            <Offcanvas.Body>
               <Collapsible trigger="होम" triggerTagName="div"
                  triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                  <ul className="sidebar_sub_menu text-white mt-3">
                     <li><NavLink to="/" onClick={() => handleScroll('home')}>होम</NavLink></li>
                     <li><NavLink to="/" onClick={() => handleScroll('home')}>होम स्टाइल 1</NavLink></li>
                  </ul>
               </Collapsible>

               <Collapsible trigger="हमारे बारे में" triggerTagName="div"
                  triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                  <ul className="sidebar_sub_menu text-white mt-3">
                     <li><NavLink to="/" onClick={() => handleScroll('about-us')}>हमारे बारे में</NavLink></li>
                  </ul>
               </Collapsible>

               <Collapsible trigger="विशेषताएँ" triggerTagName="div"
                  triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                  <ul className="sidebar_sub_menu text-white mt-3">
                     <li><NavLink to="/" onClick={() => handleScroll('features')}>विशेषताएँ</NavLink></li>
                  </ul>
               </Collapsible>

               <Collapsible trigger="समाचार और घटनाएँ" triggerTagName="div"
                  triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                  <ul className="sidebar_sub_menu text-white mt-3">
                     <li><NavLink to="/" onClick={() => handleScroll('events')}>समाचार और घटनाएँ</NavLink></li>
                  </ul>
               </Collapsible>

               <Collapsible trigger="गेलरी" triggerTagName="div"
                  triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                  <ul className="sidebar_sub_menu text-white mt-3">
                     <li><NavLink to="/" onClick={() => handleScroll('gallery')}>गेलरी</NavLink></li>
                  </ul>
               </Collapsible>

               <Collapsible trigger="संपर्क करें" triggerTagName="div"
                  triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                  <ul className="sidebar_sub_menu text-white mt-3">
                     <li><a href="/">संपर्क करें</a></li>
                  </ul>
               </Collapsible>
            </Offcanvas.Body>
         </Offcanvas>
      </div>
   );
};

export default Sidebar;
