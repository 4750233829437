import React, { useState } from 'react';

const HomeAboutArea = () => {
   const [isExpanded, setIsExpanded] = useState(false);

   const handleToggle = () => {
      setIsExpanded(!isExpanded);
   };
   return (
      <>
         <section className="about-area pt-50">
            <h1 className="heading">
               <span class="half-black">मरवाड़ी</span> <span class="half-red">समाज में आपका स्वागत है</span>
               <br />
               <img src="img/shape/section-title-line.png" alt="" />
            </h1>
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 col-lg-5 about_left">
                     {/* <div className="medical-icon-brand-2">
                        <img src="img/about/medical-brand-icon-border.png" alt=""/>
                     </div> */}
                     <div className="about-left-side pos-rel mb-30">
                        <div className="about-front-img">
                           <img src="img/about/aboutimg.png" alt="" />
                        </div>
                        <div className="about-shape">
                           <img src="img/about/about-shape.png" alt="" />
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-7">
                     <div className="about-right-side pt-10">
                        <div className="about-title mb-20">

                           <h4 style={{ color: 'black' }}>
                              मारवाड़ी समुदाय के बारे में एक संक्षिप्त कहानी
                           </h4>
                        </div>
                        <div className="about-text mb-10">
                           <p>
                              मारवाड़ी लोग अपने मजबूत सामाजिक एकजुटता के लिए जाने जाते हैं, जिसमें समुदाय के सदस्य एक-दूसरे की सहायता और समर्थन के लिए हमेशा तत्पर रहते हैं। 1960 में सेलिग हैरिसन ने इस एकजुटता का वर्णन "सबसे मजबूत क्षेत्रीय विलायकों के प्रभाव के तहत अघुलनशील" के रूप में किया। इसका मतलब है कि मारवाड़ी समुदाय ने बाहरी प्रभावों के बावजूद अपनी सांस्कृतिक और सामाजिक पहचान को बनाए रखा है।
                              <br></br>
                              मारवाड़ी समुदाय के मुख्य तत्वों में शामिल हैं:<br></br>

                              <span style={{ fontWeight: "bold" }}>व्यापारिक नेटवर्क:</span> मारवाड़ी लोग अपने व्यापारिक कौशल और नेटवर्किंग के लिए प्रसिद्ध हैं। वे व्यापार में एक-दूसरे की मदद करते हैं और सामूहिक रूप से आर्थिक सफलता प्राप्त करते हैं।

                              <br></br>
                              <span style={{ fontWeight: "bold" }}>पारिवारिक मूल्य: </span>मारवाड़ी समुदाय में परिवार और रिश्तों का बहुत महत्व है। पारिवारिक एकता और सहयोग उनके सामाजिक ढांचे का महत्वपूर्ण हिस्सा हैं।<br></br>


                              {isExpanded && (
                                 <>

                                    <span style={{ fontWeight: "bold" }}>सांस्कृतिक पहचान: </span>मारवाड़ी लोग अपनी सांस्कृतिक परंपराओं और रीति-रिवाजों को बहुत महत्व देते हैं। वे अपने धार्मिक और सांस्कृतिक उत्सवों को धूमधाम से मनाते हैं और अपनी भाषा, पहनावे, और खान-पान को संजोते हैं।<br></br>
                                    <span style={{ fontWeight: "bold" }}>समुदायिक सहायता: </span>मारवाड़ी समुदाय में परस्पर सहायता और दान का प्रचलन है। वे शिक्षा, स्वास्थ्य और सामाजिक कल्याण के क्षेत्र में अपने समुदाय के लोगों की मदद करते हैं।<br></br>

                                    इन विशेषताओं के कारण, मारवाड़ी लोग बाहरी प्रभावों से अप्रभावित रहते हुए अपनी विशिष्ट पहचान बनाए रखते हैं।
                                 </>
                              )}
                           </p>
                           <span
                              onClick={handleToggle}
                              style={{
                                 marginTop: '10px',
                                 backgroundColor: '#e74c3c',
                                 color: 'white',
                                 padding: '5px 10px',
                                 border: 'none',
                                 borderRadius: '5px',
                                 cursor: 'pointer',
                                 display: 'inline-block',
                              }}
                           >
                              {isExpanded ? 'कम दिखाएँ |' : 'और पढ़ें |'}
                           </span>

                        </div>
                        <div className="about-author d-flex align-items-center">
                           <div className="author-ava">
                              {/* <img src="img/about/author-ava.png" alt=""/> */}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeAboutArea;
